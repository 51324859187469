import React from "react";
import { autobind } from "react-decoration";
import withLocation from "lib/withLocation";
import PropTypes from "prop-types";
import { withI18next } from "lib/withI18next";
import IntegrationSearchComp from "./IntegrationSearchComp";
import ResourceAdvanceSearchComp from "./ResourceAdvanceSearchComp";

@withLocation
@withI18next(["common"])
class AdvanceSearchBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchType: this.props.searchType,
    };
  }

  @autobind
  searchComp() {
    switch (this.state.searchType) {
      case "integration":
        return <IntegrationSearchComp />;
      case "resource":
        return (
          <ResourceAdvanceSearchComp resourceType={this.props.resourceType} />
        );
      default:
    }
  }

  render() {
    return (
      <>
        <section className="col-12 search_block">
          <a
            className="accesskey"
            href="javascript:;"
            id="aS"
            accessKey="S"
            title={this.props.t("jumperrwd.common.searchArea")}>
            :::
          </a>
          <img
            className="header_bg"
            src="/file/images/header_bg.png"
            alt="搜尋框背景"
          />
          <div className="container">
            <div className="col">
              <div className="search_system">
                <div className="tabSet">
                  <ul className="tabs">
                    <li
                      className={
                        this.state.searchType === "hold" ? "active" : ""
                      }>
                      <a
                        tabIndex="0"
                        role="button"
                        onClick={() => {
                          this.setState({ searchType: "hold" });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.setState({ searchType: "hold" });
                          }
                        }}>
                        {this.props.t("jumperrwd.common.libraryCollections")}
                      </a>
                    </li>
                    <li
                      className={
                        this.state.searchType === "integration" ? "active" : ""
                      }>
                      <a
                        tabIndex="0"
                        onClick={() => {
                          this.setState({ searchType: "integration" });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.setState({ searchType: "integration" });
                          }
                        }}>
                        {this.props.t("jumperrwd.common.fulltextSearch")}
                      </a>
                    </li>
                    <li
                      className={
                        this.state.searchType === "resource" ? "active" : ""
                      }>
                      <a
                        tabIndex="0"
                        onClick={() => {
                          this.setState({ searchType: "resource" });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.setState({ searchType: "resource" });
                          }
                        }}>
                        {this.props.t("jumper.common.dbjournal")}
                      </a>
                    </li>
                  </ul>
                </div>
                {this.searchComp()}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

AdvanceSearchBlock.defaultProps = {};

AdvanceSearchBlock.propTypes = {
  t: PropTypes.func,
};

export default AdvanceSearchBlock;
